import { Button, Divider } from "antd";
import React from "react";
import { Col, Container, Row, SvgIcon } from "../../components/common";
import "./index.less";

import CswapLogo from '../../assets/images/cSwap-light-logo.svg';

const FooterBar = () => {
    return (
        <div className="footer-main">
            <Container>
                <Row>
                    <Col lg="6" sm='6' className="footer-col footerleft-col">
                        <img src={CswapLogo} alt="logo" />
                    </Col>
                    <Col lg="6" sm='6' className="footer-col footerright-col">
                        Powered By <SvgIcon name="comdex-logo" viewbox="0 0 2724.893 673.017" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Divider />
                    </Col>
                </Row>
                <Row>
                    <Col sm="6" className="footerbottom-left">
                       <span>© 2022 Cswap.</span> <span className="mx-2">|</span> 
                       <Button className="terms-link" type="link" href="https://terms.comdex.one/Comdex_Cswap_Terms_and_Conditions.pdf" target="_blank" rel="noreferrer noopener">
                        Terms & Conditions
                       </Button>
                    </Col>
                    <Col sm="6" className="footerbottom-right">
                        <div className="footer-links">
                            <Button type="link" href="https://discord.gg/comdexofficial" target="_blank" rel="noreferrer noopener">
                                <SvgIcon name="discord" viewbox="0 0 65.002 49.503" />
                            </Button>
                            <Button type="link" href="https://github.com/comdex-official" target="_blank" rel="noreferrer noopener">
                                <SvgIcon name="github" viewbox="0 0 60 59.495" />
                            </Button>
                            <Button type="link" href="https://twitter.com/cSwap_DEX" target="_blank" rel="noreferrer noopener">
                                <SvgIcon name="twitter" viewbox="0 0 55.819 46.915" />
                            </Button>
                            <Button type="link" href="https://medium.com/@cSwap_DEX" target="_blank" rel="noreferrer noopener">
                                <SvgIcon name="medium" viewbox="0 0 58.333 48" />
                            </Button>
                            <Button type="link" href="https://t.me/cSwap_DEX" target="_blank" rel="noreferrer noopener">
                                <SvgIcon name="telegram" viewbox="0 0 54.209 46.163" />
                            </Button>
                            <Button type="link" href="https://docs.cswap.one/" target="_blank" rel="noreferrer noopener">
                                <SvgIcon name='doc' viewbox='0 0 21.375 28.125' />
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FooterBar
