import { DOLLAR_DECIMALS } from '../constants/common';

export const formatNumber = (number) => {
    if (number >= 1000 && number < 1000000) {
      return (number / 1000).toFixed(DOLLAR_DECIMALS) + "K";
    } else if (number >= 1000000) {
      return (number / 1000000).toFixed(DOLLAR_DECIMALS) + "M";
    } else if (number < 1000) {
      return Number(number).toFixed(DOLLAR_DECIMALS);
    }
  };

  export const commaSeparator = (value) => {
    const array = value.toString().split(".");
    const stringWithComma = array[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  
    if (array[1]) {
      return stringWithComma.concat(".", array[1]);
    }
  
    return stringWithComma;
  };
  