import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import CswapLogo from '../../assets/images/cSwap-light-logo.svg';
import { Container } from "../../components/common";
import TermsModal from "../TermsModal";
import "./index.less";


const NavigationBar = () => {
  return (
    <>
      <Container>
        <div
          className="header-inner"
          data-aos="fade-down"
          data-aos-duration="600"
        >
          <div className="left-menu">
            <Button onClick={() =>{ let newWindow = window.open("https://comdex.one/#ecosystem", "_blank");
            newWindow.opener = null;}}>
              Comdex Ecosystem
            </Button>
          </div>
          <div className="logo">
            <Link to="/">
              <img src={CswapLogo} alt="logo" />
            </Link>
          </div>
          <div className="right-btn">
            <TermsModal headerLaunchButton={true} />
          </div>
        </div>
      </Container>
    </>
  );
};

export default NavigationBar;
