import { Divider } from "antd";
// import humanizeDuration from "humanize-duration";
import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
// images
import BannerBg from "../../assets/images/banner-bg.svg";
import InterChain from "../../assets/images/interchain-image.svg";
import EcosystemWide from "../../assets/images/wcosystem-wide.png";
import { Col, Container, Row, SvgIcon } from "../../components/common";
import {
  fetchInterchainTransactions,
  fetchTVL,
  fetchVolume
} from "../../services/query";
import { commaSeparator, formatNumber } from "../../utils/number";
import "./index.less";

const HomePage = () => {
  const [totalVolumeLocked, setTotalVolumeLocked] = useState(0);
  const [totalVolume, setTotalVolume] = useState(0);
  const [interchainTxs, setInterchainTxs] = useState(0);

  useEffect(() => {
    fetchTVL((error, result) => {
      if (error) {
        return;
      }

      setTotalVolumeLocked(result?.data?.tvl);
    });
    fetchVolume((error, result) => {
      if (error) {
        return;
      }

      setTotalVolume(result?.data);
    });

    fetchInterchainTransactions((error, result) => {
      if (error) {
        return;
      }

      setInterchainTxs(result?.data);
    });
  }, []);

  return (
    <div className="homepage-wrapper">
      <section className="banner-section">
        <div
          className="bannerbg-image"
          style={{ backgroundImage: `url(${BannerBg})` }}
        ></div>
        <Container>
          <Row className="banner-row">
            <Col>
              <h1 data-aos="fade-up" data-aos-duration="400">
                ORDERBOOK <span>STYLE INTERCHAIN</span> DEX
              </h1>
              <p className="build-text">
                {" "}
                Built on{" "}
                <SvgIcon name="comdex-logo" viewbox="0 0 2724.893 673.017" />
              </p>
              <p className="mt-2">Swap, Trade, Farm, Govern through cSwap</p>
              {/* <div
                className="text-center"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                <TermsModal bannerLaunchButton />
              </div> */}
              {/* <div
                className="start-btn"
                data-aos="zoom-in"
                data-aos-duration="600"
              >
                <a href="#aboutSection" smooth="true">
                  <div className="start-circle">
                    <div className="start-circle-inner">
                      <SvgIcon name="arrow-down" viewbox="0 0 13 21" />
                    </div>
                  </div>
                </a>
              </div> */}

              <ul>
                <li>
                  <div className="statics-card">
                    <div className="left-icon">
                      <SvgIcon name="tvl-icon" viewbox="0 0 37.019 47.37" />
                    </div>
                    <div className="right-dlt">
                      <p>TVL</p>
                      <h2>${formatNumber(totalVolumeLocked || 0)}</h2>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="statics-card">
                    <div className="left-icon">
                      <SvgIcon name="trade-volume" viewbox="0 0 56 30" />
                    </div>
                    <div className="right-dlt">
                      <p>24HR Trading Volume</p>
                      <h2>${formatNumber(totalVolume?.trade_volume || 0)}</h2>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="statics-card">
                    <div className="left-icon">
                      <SvgIcon name="interchain-icon" viewbox="0 0 36.464 42.526" />
                    </div>
                    <div className="right-dlt">
                      <p>Interchain Transfers</p>
                      <h2>{interchainTxs?.interchain_transactions > 0
                        ? `${commaSeparator(
                          interchainTxs?.interchain_transactions
                        )}+`
                        : 0}</h2>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="about-section" id="aboutSection">
        <Container>
          <Row className="main-heading">
            <Col>
              <h2>
                cSWAP PROVIDES ECOSYSTEM WIDE LIQUIDITY <br />
                FOR STAKEHOLDERS{" "}
              </h2>
              <Divider />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="about-inner">
                <div className="about-image">
                  <img src={EcosystemWide} alt="About" />
                </div>
                <div className="about-card about-card1">
                  <div className="line-icon">
                    <SvgIcon name="about-line1" viewbox="0 0 74.603 115.814" />
                  </div>
                  <h4>Orderbook-style DEX</h4>
                  <p>
                    to become the principal liquidity source for the Comdex
                    ecosystem
                  </p>
                </div>
                <div className="about-card about-card2">
                  <div className="line-icon">
                    <SvgIcon name="about-line3" viewbox="0 0 61.513 188.402" />
                  </div>
                  <h4>Go to Platform</h4>
                  <p>
                    for traders and arbitrageurs to implement sophisticated
                    trading strategies
                  </p>
                </div>
                <div className="about-card about-card3">
                  <div className="line-icon">
                    <SvgIcon name="about-line2" viewbox="0 0 74.603 115.814" />
                  </div>
                  <h4>AMM Liquidity Pools </h4>
                  <p>
                    which bring cross-chain markets and limit orders to DeFi
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="interchain-section">
        <Container>
          <Row className="main-heading">
            <Col>
              <h2>
                RAPIDLY-GROWING INTERCHAIN DEX ON <br /> COMDEX BLOCKCHAIN
              </h2>
              <Divider />
              <p>Interoperable, sustainable, and decentralized exchange</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="interchain-image">
                <img src={InterChain} alt="interchain" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="statics-section" id="staticsSection">
        <Container>
          <Row className="main-heading">
            <Col>
              <h2>A New Age DeFi Community</h2>
              <Divider />
              <p>Dive early into the ever-evolving DeFi and grow with it</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul>
                <li>
                  <div className="statics-card">
                    <div className="left-icon">
                      <SvgIcon name="wallet-icon" viewbox="0 0 53 47" />
                    </div>
                    <div className="right-dlt">
                      <p>Wallets Supported</p>
                      <h2>4</h2>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="statics-card">
                    <div className="left-icon">
                      <SvgIcon name="chains-icon" viewbox="0 0 50.991 51.106" />
                    </div>
                    <div className="right-dlt">
                      <p>Chains Supported</p>
                      <h2>10+</h2>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="statics-card">
                    <div className="left-icon">
                      <SvgIcon name="members-icon" viewbox="0 0 57 46" />
                    </div>
                    <div className="right-dlt">
                      <p>Discord Members</p>
                      <h2>39.8k</h2>
                    </div>
                  </div>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default HomePage;
