import axios from "axios";
import { API_URL } from "../constants/url";

export const fetchTVL = (callback) => {
  axios
    .get(`${API_URL}/api/v2/cswap/home/tvl`)
    .then((result) => {
      callback(null, result?.data);
    })
    .catch((error) => {
      callback(error?.message);
    });
};

export const fetchVolume = (callback) => {
  axios
    .get(`${API_URL}/api/v2/cswap/home/volume`)
    .then((result) => {
      callback(null, result?.data);
    })
    .catch((error) => {
      callback(error?.message);
    });
};

export const fetchInterchainTransactions = (callback) => {
    axios
      .get(`${API_URL}/api/v2/cswap/home/ict`)
      .then((result) => {
        callback(null, result?.data);
      })
      .catch((error) => {
        callback(error?.message);
      });
  };
  