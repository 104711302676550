import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout } from "antd";
import SvgSprite from "./utils/SvgSpriteLoader";
import history from './common/history';
import AOS from 'aos';
import svgFile from './assets/images/svg/svg-sprite.svg';
import NavigationBar from './containers/NavigationBar';
import 'aos/dist/aos.css'; 
import './App.less';
import FooterBar from './containers/FooterBar';
import HomePage from './containers/Homepage';

const { Header, Content, Footer } = Layout;
AOS.init();

class App extends React.Component { 
  render(){
    return (
      <React.Fragment>
        <SvgSprite url={svgFile} />
        <Router history={history}>
          <Layout>
            <Header className="header">
              <NavigationBar />
            </Header>
            <Content>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/home" element={<HomePage />} />
              </Routes>
            </Content>
            <Footer className='footer-bottom'>
              <FooterBar />
            </Footer>
          </Layout>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;